"use client";

import Button from "../button";
import ArrowUpIcon from "@/icons/arrow-up";
import cn from "@/utils/cn";

export default function ScrollToTopButton() {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <Button
      onClick={scrollToTop}
      aria-label="scroll to top"
      className={cn(
        "fixed right-6 bottom-6 xl:right-15 xl:bottom-15 h-12 w-12 rounded-full",
        "bg-primary-400 text-white stroke-[1.5]",
      )}
    >
      <ArrowUpIcon />
    </Button>
  );
}
