import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Playfair_Display\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--serif\",\"display\":\"swap\"}],\"variableName\":\"serif\"}");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--sans\",\"display\":\"swap\"}],\"variableName\":\"sans\"}");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/public/assets/images/amen_estate_logo@2x.png");
;
import(/* webpackMode: "eager" */ "/builds/amen-estate/amen-estate-website/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/builds/amen-estate/amen-estate-website/src/components/navbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/amen-estate/amen-estate-website/src/components/scroll-to-top-button/index.tsx");
